<template>
  <div>
    <!-- TODO: szótárazni -->
    <div>EESZT profil adatok</div>
    <b-row cols="1" cols-md="2" v-if="profile">
      <b-col>
        <fp-input
          :disabled="disabled"
          label="Felhasználó azonosító"
          v-model="profile.EesztUserId"
          @change="changeValue()"
          :required="true"
        />
      </b-col>
      <b-col>
        <fp-input
          :disabled="disabled"
          label="Felhasználónév"
          v-model="profile.EesztFullName"
          @change="changeValue()"
          :required="true"
        />
      </b-col>
      <b-col>
        <fp-input
          :disabled="disabled"
          label="ENKK azonosító"
          v-model="profile.EnkkId"
          @change="changeValue()"
          :required="true"
        />
      </b-col>
    </b-row>
    <div>Intézmények</div>
    <b-row
      v-if="profile && profile.OrganizationUnits && organizationIds.length"
      cols="1"
      cols-md="2"
    >
      <b-col
        class="my-2"
        v-for="(organization, index) in profile.OrganizationUnits"
        :key="'eeszt-profile-organization-' + organizationIds[index]"
      >
        <fp-panel-card
          :title="index + 1 + '. intézmény'"
          :isShowAddButton="!disabled"
          :isShowDeleteButton="profile.OrganizationUnits.length > 1"
          @addNewElement="addNewUnitToProfile(index)"
          @deleteElement="deleteUnitFromProfile(index)"
        >
          <template #content>
            <fp-input
              :disabled="disabled"
              label="Intézmény rövid neve"
              v-model="organization.Nickname"
              :required="true"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              label="Intézmény EESZT azonosítója"
              v-model="organization.OrganizationEesztId"
              :required="true"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              label="Intézmény NNK azonosítója"
              v-model="organization.OrganizationNnkId"
              :required="true"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              label="Intézmény teljes neve"
              v-model="organization.OrganizationName"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              label="Szervezeti egység EESZT azonosítója"
              v-model="organization.OrganizationUnitEesztId"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              label="Szervezeti egység NNK azonosítója"
              v-model="organization.OrganizationUnitNnkId"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              label="Szervezeti egység teljes neve"
              v-model="organization.OrganizationUnitName"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              :required="true"
              label="Telefonszám"
              v-model="organization.PhoneNumber"
              @change="changeValue()"
            />
            <fp-input
              :disabled="disabled"
              :required="true"
              label="Cím"
              v-model="organization.Address"
              @change="changeValue()"
            />
          </template>
        </fp-panel-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "EesztProfile",
  model: {
    prop: "eesztProfile",
    event: "change",
  },
  props: {
    eesztProfile: Object,
    disabled: Boolean,
  },
  data() {
    return {
      profile: this.eesztProfile,
      defaultProfile: {
        UserId: null,
        UserName: null,
        OrganizationUnits: [
          {
            Nickname: null,
            OrganizationId: null,
            OrganizationName: null,
            OrganizationUnitId: null,
            OrganizationUnitName: null,
          },
        ],
      },
      organizationIds: [],
      organizationCount: 0,
    };
  },
  methods: {
    changeValue() {
      this.$emit("change", JSON.parse(JSON.stringify(this.profile)));
    },
    addNewUnitToProfile(index) {
      this.organizationCount++;
      this.organizationIds.splice(index + 1, 0, this.organizationCount);
      this.profile.OrganizationUnits.splice(index + 1, 0, {});
      this.changeValue();
    },
    deleteUnitFromProfile(index) {
      this.organizationIds.splice(index, 1);
      this.profile.OrganizationUnits.splice(index, 1);
      this.changeValue();
    },
    setProfileData() {
      if (this.eesztProfile) {
        this.profile = JSON.parse(JSON.stringify(this.eesztProfile));
      } else {
        this.profile = JSON.parse(JSON.stringify(this.defaultProfile));
      }
      this.organizationCount = this.profile.OrganizationUnits.length;
      this.organizationIds = Array.from(Array(this.organizationCount).keys());
    },
  },
  mounted() {
    this.setProfileData();
  },
};
</script>
